<template>
    <b-modal id="projectImportWizard" ref="projectImportWizard" v-b-modal.modal-multi-2 size="xl"
        @cancel="onItemCancel" @ok="onItemOk" @show="onItemShow" :ok-disabled="!validate()"
        :title="$t('project.import')" :cancel-title="$t('Cancel')" :ok-title="$t('Ok')">
        <b-form>
            <b-row>
                <b-col cols="6">
                    <b-form-file
                        v-model="ptysFile"
                        :state="Boolean(ptysFile)"
                        :placeholder="$t('project.import.ptysFile')"
                        :drop-placeholder="$t('project.import.ptysFileDrop')"
                        accept=".PTYS, .ptys"/>
                </b-col>
                <b-col cols="6">
                    <b-form-file
                        v-model="keyFile"
                        :state="Boolean(keyFile)"
                        :placeholder="$t('project.import.keyFile')"
                        :drop-placeholder="$t('project.import.keyFileDrop')"/>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group :label="$t('project.name')">
                        <b-form-input type="text" v-model="projectName" :state="validateName()"/>
                        <b-form-invalid-feedback id="projectVersion-feedback">{{ feedbackName }}</b-form-invalid-feedback>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-checkbox style="margin-top: 35px;" v-model="compatibilityMode">{{$t('project.import.compatibilityMode')}}</b-form-checkbox>
                </b-col>
            </b-row>
        </b-form>
    </b-modal>
</template>

<script>
import ProdComProjectService from "@/services/prodcom.projects.service.js";
import utils from "@/utils";
import gzip from 'gzip-js';

export default {
    name: "ProjectImportWizard",
    props: {
        /**
         * List of existing projects, for name validation.
         */
        propExistingProjects: Array,
        onOk: Function,
    },
    data(){
        return {
            ptysFile: null,
            keyFile: null,
            projectName: "Productys Manager",
            compatibilityMode: false,
            feedbackName: "",
            contentKeyFile: "",

            contentPtysFile: [],
        }
    },
    methods: {
        validate(){
            return this.validateName() && Boolean(this.ptysFile);
        },
        validateName(){
            if(this.projectName == null || this.projectName == ""){
                this.feedbackName = this.$t('project.feedbackName.empty');
                return false;
            }
            this.feedbackName = "";
            return true;
        },
        
        async onItemShow(e){

        },

        /**
         * Asynchronously reads the binary content of the given file and returns it as
         * a base64 encoded string. From https://simon-schraeder.de/posts/filereader-async/
         * @param {File} File The file to be read
         * @returns {Promise<String>} The base64 encoded content of the file.
         */
        async readFileAsDataUrlAsync(file){
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result);
                }
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        },

        /**
         * Asynchronously reads the content of the given file as a string
         * with the specified encoding. From https://simon-schraeder.de/posts/filereader-async/
         * @param {File} File The file to be read
         * @param {String} encoding The encoding to be used
         * @returns {Promise<String>} The content of the file.
         */
        async readFileAsTextAsync(file, encoding){
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result);
                }
                reader.onerror = reject;
                reader.readAsText(file, encoding);
            });
        },
        /**
         * Splitting a file to send it to the back packets per packets
         */
        async splitAndSend(str) {
            const blocksize = 32 * 1024 * 10 ; 
            let compressedData = '';
            let blockIndex = 0;
            while (blockIndex * blocksize < str.length) {
               let block;
                block = str.slice(blockIndex * blocksize, (blockIndex + 1) * blocksize);
                let settings = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ content: block }),
                };
                let res = await fetch(`${this.$store.state.settings.API_URL}/ImportBufferContent`, settings);
                blockIndex++;
            }
            console.log(`${blockIndex} packets sent with ${blocksize} characters of data`);
            return compressedData;
        },
        /**
         * Called when the 'OK' button is clicked. Sends the given data back to the back end to be imported. 
         */
        async onItemOk(e){
            this.$EventBus.$emit('Loading');
            let contentPtysFile = await this.readFileAsDataUrlAsync(this.ptysFile);
            let contentPtysFileEncoded = contentPtysFile.split(",", 2)[1];

            let contentKeyFile = "";
            if(this.keyFile != null && this.keyFile != "" )contentKeyFile = await this.readFileAsTextAsync(this.keyFile, 'UTF-8');
            else{
                var out = gzip.zip(atob(contentPtysFileEncoded), {
                    level: 3,
                    name: 'config.xml',
                    timestamp: parseInt(Date.now() / 1000, 10)
	            })
                let str = Buffer.from(out).toString('base64');                
                contentPtysFileEncoded = str;
            }
            this.$EventBus.$emit('Loading');
            //let compressedString = await this.splitAndSend(contentPtysFileEncoded);
            let obj = {
                    ProjectName: this.projectName,
                    CompatibilityMode: this.compatibilityMode,
                    ContentPtys: contentPtysFileEncoded,
                    ContentKey: contentKeyFile
                }

            const settings = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(obj),
            };
            
            const res = await fetch(`${this.$store.state.settings.API_URL}/ImportProject`, settings);
            //let  res = await fetch(`${this.$store.state.settings.API_URL}/project.Import`, settings);

            // let contentBody = JSON.stringify();
            // console.log("2")
            // const settings = {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: objStr,
            // };
            // console.log("3")
            // const res = await fetch(`${this.$store.state.settings.API_URL}/ImportProject`, settings);
            // const data = await res.json();
            // console.log("4")

            //await ProdComProjectService.importProject(this.projectName, this.compatibilityMode, contentPtysFileEncoded, contentKeyFile);
            
            if(this.onOk != null) await this.onOk();
        },

        async onItemCancel(e){

        },
    }
}
</script>