<template>
    <div>
        <b-tab>
            <!-- <b-tab :lazy="tabLazy" @click="tabLazy = false"> -->
            <template #title>
                {{ $t('actions.operations.title') }}
                <font-awesome-icon icon="fa fa-warning" class="ml-1 text-danger" v-if="invalidState" />
            </template>
            <b-card>
                <!--<b-button v-on:click="alert()" :title="$t('Help')">
                    <font-awesome-icon icon="fas fa-square-question" class="top-menu-icon mr-1 fa-lg" />
                </b-button>-->
                <b-form>
                    <b-row>
                        <b-col cols="6">
                            <b-form-group :label="$t('actions.operations.destinationGroup') + ' *'" label-for="destinationGroup">
                                <b-form-select id="destinationGroup" v-model="destinationGroup" :state="destinationGroupState" :options="availableGroups" v-on:change="destinationGroupChanged" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="6">
                            <b-form-group :label="$t('actions.operations.destinationItem') + ' *'" label-for="destinationItem">
                                <b-form-select id="destinationItem" v-model="destinationItem" :state="destinationItemState" :options="availableDestinationItems" :class="{ disabled: !$store.state.auth.user.permissions.PermissionWrite }" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <CodeEditor ref="editor" :Formula="action.ActionConfiguration?.Formula" :propProject="this.propProject" style="height: calc(100vh - 500px)" />
                </b-form>
            </b-card>
        </b-tab>
    </div>
</template>

<script>
import ToastAlert from '@/utils/ToastAlert';
import TreeView from '@/components/TreeView.vue';
import CodeEditor from '@/components/CodeEditor.vue';
import ProdComEquipmentService from '@/services/prodcom.equipments.service.js';
import { uuid } from 'vue-uuid';
export default {
    name: 'Operations',
    props: {
        /**
         * Action object from parent component. Contains the equipment concerned,
         * and details about the action if opening an existing action.
         */
        action: Object,
        /**
         * Current project's Guid
         */
        propProject: String,
    },
    components: {
        ToastAlert,
        TreeView,
        CodeEditor,
    },
    data() {
        return {
            tabLazy: true,
            destinationGroup: '',
            destinationItem: '',
            idEditor: `monaco-editor-${uuid.v4()}`, // Generate a unique ID to avoid conflicts when mounting the editor.
            editor: null,
            availableGroups: [],
            availableDestinationItems: [],
        };
    },
    computed: {
        invalidState() {
            return this.destinationGroupState == false || this.destinationItemState == false;
        },
        destinationGroupState() {
            return this.destinationGroup == null || this.destinationGroup == '' ? false : null;
        },
        destinationItemState() {
            return this.destinationItem == null || this.destinationItem == '' ? false : null;
        },
    },
    async mounted() {
        // Get groups
        var data = await ProdComEquipmentService.getGroups(this.action.Equipment, this.propProject);
        //let myVariablesColors = new Array();
        if (data.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(data.ret), 'warning'));
        else {
            this.availableGroups = data.ret.List;
        }
        // Get back value from existing configuration
        if (this.action.ActionConfiguration != null) {
            this.destinationGroup = this.action.ActionConfiguration?.DestinationGroup == null || this.action.ActionConfiguration?.DestinationGroup == '' ? (this.availableGroups ? this.availableGroups[0] : null) : this.action.ActionConfiguration?.DestinationGroup;
            await this.destinationGroupChanged();
            this.destinationItem = this.action.ActionConfiguration?.DestinationItem == null || this.action.ActionConfiguration?.DestinationItem == '' ? (this.availableItems ? this.availableItems[0] : null) : this.action.ActionConfiguration?.DestinationItem;
        }
    },
    methods: {
        /**
         * Called by parent when validating the configuration.
         * Needs to return the current configuration of the action.
         */
        async validate() {
            if (this.invalidState) return null;
            var result = {
                DestinationGroup: this.destinationGroup,
                DestinationItem: this.destinationItem,
                Formula: this.$refs.editor.getValue(),
            };
            return result;
        },
        /**
         * Query server for list of variables when group has changed
         */
        async destinationGroupChanged(e) {
            const result = await ProdComEquipmentService.getItems(this.action.Equipment, this.destinationGroup, this.propProject);
            if (result.success == 'n') this.$EventBus.$emit('show-toast', new ToastAlert(this.$t(result.ret, result.retParams), 'warning'));
            else {
                this.availableDestinationItems = [];
                for (const [key, val] of Object.entries(result.ret.List)) {
                    this.availableDestinationItems.push(val.Name);
                }
            }
        },
    },
};
</script>
<style lang="scss">
.highlight-blue {
    background-color: blue;
    color: white;
}
</style>